import { isLoggedIn, getToken, removeToken, API_URL } from './';
import { useState, useEffect } from 'react';

export const fetcher = (signal, method, route, content = null) => {
    const options = {
        signal: signal,
        method: method,
        headers: {
            Accept: "application/json"
        },
        mode: "cors"
    };

    if (isLoggedIn()) {
        options.headers["Authorization"] = `Bearer ${getToken()}`;
    }

    if (content !== null) {
        options.headers["Content-Type"] = "application/json";
        options.body = JSON.stringify(content);
    }
    
    return fetch(`${API_URL}${route}`, options)
        .then(res => {
            if (res.status === 401 && isLoggedIn())
                return Promise.reject("INVALID_TOKEN");

            if (res.status === 500)
                return Promise.reject("UNKNOWN_ERROR");
            
            if ((res.status < 200 || res.status >= 300) && res.status !== 401)
                return Promise.reject("UNKNOWN_ERROR");
            
            if(res.status === 204)
                return res.status;

            if (res.headers.get("Content-Type").indexOf("application/json") !== -1)
                return res.json();
            
            return res.status === 200 ? res.text() : res.status;
        })
        .catch(err => {
            console.log(err);

            if (err === "INVALID_TOKEN") {
                removeToken();
                window.location = "/";
            }
            else if (err === "UNKNOWN_ERROR") {
                window.location = "/500";
            }
            else if (err.name !== 'AbortError') {
                window.location = '/networkerror';
            }

            return Promise.reject();
        });
};

const useFetch = (method, route, content = null) => {
    const [response, setResponse] = useState();

    useEffect(() => {
        const abortController = new AbortController();
        const signal = abortController.signal;

        fetcher(signal, method, route, content)
            .then(res => setResponse(res));

        return () => abortController.abort();
    }, [method, route, content]);

    return response;
};

export default useFetch;
